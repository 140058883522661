import React from "react"
import { Helmet } from "react-helmet"
import { ChevronRight20, Renew20 } from "@carbon/icons-react"

const LoginDemoPage = () => {
  return (
    <main className="p-2 lg:p-10 flex justify-center align-middle">
      <Helmet
        htmlAttributes={{ lang: "de" }}
        bodyAttributes={{
          class: "theme-default h-screen bg-gray-100",
        }}
      ></Helmet>
      <section className="p-2 md:p-10 w-full md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 border border-gray-50 bg-white shadow-lg">
        <header className="flex flex-col pb-10">
          <div className="block w-full h-14 px-4 pt-4 pb-2 mb-5 text-center">
            <img
              className="inline-block w-auto h-full"
              src="https://relaunch.rothenberger.com/ro-logo.svg"
              alt="Rothenberger"
              title="Rothenberger"
            />
          </div>
          <div className="block w-full text-gray-800 text-sm pt-5 border-t border-gray-50">
            <p className="pb-5">
              Willkommen beim ROTHENBERGER-Login. Für die Nutzung bestimmter
              Bereiche der Website ist ein Nutzer-Konto notwendig.
            </p>
          </div>
          <div className="block w-full text-gray-300 text-sm pt-5">
            <p>
              Hier können Sie Sich mit Ihrem bestehenden Website-Konto oder
              Ihrem Bonusprogramm-Konto einloggen oder eine Neu-Registrierung
              durchführen.
            </p>
          </div>
        </header>

        <div className="flex justify-center">
          <input
            className="w-2/3 h-12 py-4 px-4 placeholder-gray-300 text-sm text-gray-800 border border-gray-100 focus:border-primary focus:outline-none"
            type="text"
            name="email"
            placeholder="Ihre E-Mail-Adresse"
          />
          <button className="w-1/3 h-12 btn btn-primary">
            Weiter <ChevronRight20 className="inline-block" />
          </button>
        </div>

        <div className="flex justify-center">
          <input
            className="w-2/3 h-12 py-4 px-4 placeholder-gray-300 text-sm text-gray-800 border border-gray-100 focus:border-primary focus:outline-none"
            type="text"
            name="email"
            placeholder="Ihre E-Mail-Adresse"
          />
          <button className="w-1/3 h-12 btn btn-primary">
            Weiter{" "}
            <Renew20 className="inline-block animate-spin transform rotate-180" />
          </button>
        </div>

        <footer className="pt-5 mt-10 border-t border-gray-50">
          <span className="block w-full text-center text-xs text-gray-200">
            © {new Date().getFullYear()} ROTHENBERGER Werkzeuge GmbH
          </span>
        </footer>
      </section>
    </main>
  )
}
export default LoginDemoPage
